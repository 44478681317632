@import '../variables.css';

.cookieBar {
    background-color: var(--cookies-background-color);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: auto;
    max-width: 300px;
    z-index: 1000;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cookieBar p {
    margin: 0;
    flex: 1;
    font-size: 12px;
    color: var(--cookies-text-color);
}

.cookieBar a {
    color: var(--cookies-link-color);
    text-decoration: none;
    font-weight: var(--cookies-link-font-weight);
}

.cookieBar a:hover {
    text-decoration: underline;
}

.confirmButton {
    background-color: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    font-weight: var(--button-font-weight);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 12px;
}

.confirmButton:hover {
    background-color: var(--button-secondary-hover-background-color);
    color: var(--button-secondary-hover-text-color);
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
    .cookieBar {
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        width: 90%;
        max-width: none;
        padding: 15px;
        flex-direction: column;
    }

    .cookieBar p {
        margin-bottom: 10px;
        width: 100%;
        font-size: 14px;
    }

    .confirmButton {
        width: 100%;
        text-align: center;
    }
}
