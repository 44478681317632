:root {
     /*general*/
    --background-color: #fff;
    --header-color: #20d8ef;
    --text-light-color: #ffffff;
    --text-dark-color: #000;
    --font-family:  "Roboto", sans-serif;
     /*buttons*/
    --button-primary-text-color: #fff;
    --button-secondary-text-color: #fff;
    --button-primary-background-color: #f2b82f;
    --button-secondary-background-color: #f2b82f;
    --button-primary-hover-background-color: #9cc811;
    --button-secondary-hover-background-color: #9cc811;
    --button-primary-hover-text-color: #fff;
    --button-secondary-hover-text-color: #fff;
    --button-font-weight: 550;
    --button-border-radius: 5px;
     /*placeholder*/
     --placeholder-color: #999;
     /*product badge*/
     --badge-background-color: #f03158;
     --badge-text-color: #fff;
     --badge-border-radius: 3px;
     --badge-font-weight: 550;
     /*footer*/
     --footer-background-color: #20d8ef;
     --footer-link-color: #fff;
     --footer-copy-color: #fff;
     /*cookies*/
     --cookies-background-color: #f03158;
     --cookies-text-color: #ffffff;
     --cookies-link-color: #9cc811;
     --cookies-copy-color: #fcffde;
     --cookies-link-font-weight: 550;
  }
  