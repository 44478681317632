@import '../variables.css';
.container {
    padding: 20px 10%;
    padding-bottom: 100px;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
    padding: 0;
  }
  
 
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
  
  @media (max-width: 1100px) {
    .grid {
      grid-template-columns: repeat(2, 1fr); /* Two items per row on medium devices */
    }
    .container {
        padding: 20px 2%;
      }
  }
  
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: 1fr; /* One item per row on small devices */
    }
  }
  